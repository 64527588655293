import React, {Component, useEffect} from "react";
import ReactDOM from 'react-dom';
import QrReader from "modern-react-qr-reader";
import ReactPlayer from "react-player";
import '../css/Main.css';
import ResponseMsg from "./ResponseMsg";
import {extractPidFromQrCode} from "./helperFunctions";
import ConfigConst from "../config.json";
import ComplexResponseMsg from "./ComplexResponseMsg";

class Main extends Component {
    timer = null;

    constructor(props) {
        super(props)
        this.state = {
            pageStart: true,
            pageScan: false,
            pageVideo: false,
            pageError: false,
            response: null,
            videoUrl: "",
            langId: ConfigConst.LANG.DEFAULT,
            errorMsg: ""
        };
    }

    setPageStart() {
        this.setState({
            pageStart: true,
            pageScan: false,
            pageVideo: false,
            pageError: false,
            response: null,
            videoUrl: "",
            errorMsg: ""
        })
    }

    setPageScan() {
        console.log("setPageScan");
        this.setTimeoutIfNotNull(this.setPageStart, ConfigConst.TIMEOUT.SCAN);
        this.setState({
            pageStart: false,
            pageScan: true,
            pageVideo: false,
            pageError: false,
        });
        console.log(this.state);
    }


    setPageVideo(rsp) {
        clearTimeout(this.timer);
        this.setState({
            pageStart: false,
            pageScan: false,
            pageVideo: true,
            pageError: false,
            response: rsp,
            videoUrl: rsp.videoUrl
        })
    }


    setPageError(msg) {
        this.setTimeoutIfNotNull(this.setPageStart, ConfigConst.TIMEOUT.ERROR);
        this.setState({
            pageStart: false,
            pageScan: false,
            pageVideo: false,
            pageError: true,
            errorMsg: msg
        })
    }


    setTimeoutIfNotNull(callback, timeout) {
        clearTimeout(this.timer);
        if (timeout > 0) {
            console.log("setTimeoutIfNotNull" + timeout);
            this.timer = setTimeout(callback.bind(this), 1000 * timeout);
        }
    }

    setVideoTimeout(duration) {
        let timeout = ConfigConst.TIMEOUT.VIDEO;
        console.log("setVideoTimeout:" + duration + "+" + timeout);
        if (timeout > 0) {
            timeout = timeout + Math.round(duration);
            this.setTimeoutIfNotNull(this.setPageStart, timeout);
        }
    }


    portfolioApiCall(qrCode) {
        let pid = extractPidFromQrCode(qrCode);
        fetch("/startUserByPid?pid=" + pid + "&langId=" + this.state.langId)
            .then(res => res.json())
            .then(
                (response) => {
                    if (response.status > 202) {
                        console.log("Response:" + response.toString());
                        this.handleError(response.msg);
                    } else {
                        this.setPageVideo(response);
                    }
                },
                (error) => {
                    this.handleError(error);
                }
            )
    }


    handleScan = async (scanData) => {
        console.log(`loaded data data`, scanData);
        if (scanData && scanData !== "") {
            console.log(`loaded >>>`, scanData);
            this.portfolioApiCall(scanData);
        }
    };

    handleError = (err) => {
        console.error("FrontendErrorHandlingApiCall:"+err);
        this.setPageError(err);
    };

    getButtonText() {
        if (this.state.pageStart) {
            return this.getLangValueFromConfig(ConfigConst.MSG.BTN.CheckIn);
        } else if (this.state.pageVideo || this.state.pageError) {
            return this.getLangValueFromConfig(ConfigConst.MSG.BTN.StartPage);
        } else {
            return this.getLangValueFromConfig(ConfigConst.MSG.BTN.Restart);
        }
    }

    getLangValueFromConfig(param) {
        return this.getLangValueFromConfigByLangId(param, this.state.langId, "")
    }


    getLangValueFromConfigByLangId(param, langId, msg) {
        if (typeof param != "undefined") {
            console.log(Object.entries(param));
            if (langId == ConfigConst.LANG.ENG.ID) {
                console.log(param[ConfigConst.LANG.ENG.CODE])
                return param[ConfigConst.LANG.ENG.CODE] + msg;
            } else {
                console.log(param[ConfigConst.LANG.GER.CODE])
                return param[ConfigConst.LANG.GER.CODE] + msg;
            }
        } else {
            console.log("Config not yet loaded:" + msg)
            return "Error Config not loaded, please report to front desk.";
        }
    }


    getFooter() {
        if (ConfigConst.LANG.ENABLE) {
            return <div id="selectLang"><select onChange={(val) => this.setLangId(val.target.value)}
                                                defaultValue={ConfigConst.LANG.DEFAULT}
                                                className="btn btn-sm btn-outline-secondary dropdown-toggle">
                <option value={ConfigConst.LANG.ENG.ID}>{ConfigConst.LANG.ENG.NAME}</option>
                <option value={ConfigConst.LANG.GER.ID}>{ConfigConst.LANG.GER.NAME}</option>
            </select>
            </div>
        } else {
            return <div></div>
        }
    }

    setLangId(selectedLangId) {
        this.setState({
            langId: selectedLangId,
        })
        console.log(this.state.langId)
    }

    getDisplayMsg() {
        if (this.state.pageError) {
            return this.getLangValueFromConfigByLangId(ConfigConst.MSG.STD.ERROR, this.state.langId, this.state.errorMsg)
        } else if (this.state.pageVideo) {
            return this.getLangValueFromConfigByLangId(ConfigConst.MSG.STD.VIDEO, this.state.langId, this.state.response.msg)
        } else if (this.state.pageScan) {
            return this.getLangValueFromConfigByLangId(ConfigConst.MSG.STD.SCAN, this.state.langId, "")
        } else {
            return this.getLangValueFromConfigByLangId(ConfigConst.MSG.STD.WLCM, this.state.langId, "")
        }
    }


    render() {
        return (
            <div id="main">
                {(this.state.pageScan || this.state.pageStart || this.state.pageError) && (
                    <>
                        <ResponseMsg msg={this.getDisplayMsg()} error={this.state.pageError}></ResponseMsg>
                    </>
                )}
                {this.state.pageScan && (
                    <div id="parent">
                        <QrReader
                            facingMode='user'
                            delay={1000}
                            onError={this.handleError}
                            onScan={this.handleScan.bind(this)}
                            // chooseDeviceId={()=>selected}
                            style={{width: "300px", margin: "0 auto"}}
                            showViewFinder={true}
                        />
                    </div>
                )}
                {this.state.pageVideo && (
                    <>
                        <div id="parent">
                            <ComplexResponseMsg rsp={this.state.response}
                                                langId={this.state.langId}></ComplexResponseMsg>

                            {this.state.videoUrl !== "" && (
                                <>
                                    <ReactPlayer
                                        url={this.state.videoUrl}
                                        playing={true}
                                        controls={false}
                                        onDuration={(duration) => this.setVideoTimeout(duration)}
                                        style={{width: "100%", margin: "0 auto"}}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
                {(this.state.pageStart) && (
                    <>
                        <button className="button-9" role="button" key={this.getButtonText()} onClick={() => {
                            if (this.state.pageStart) {
                                this.setPageScan();
                            } else {
                                this.setPageStart();
                            }
                        }}>
                            {this.getButtonText()}
                        </button>
                    </>
                )
                }
                {(this.state.pageScan) && (
                    <>
                        <button className="button-9" role="button" key={this.getButtonText()} onClick={() => {
                            if (this.state.pageStart) {
                                this.setPageScan();
                            } else {
                                this.setPageStart();
                            }
                        }}>
                            {this.getButtonText()}
                        </button>
                    </>
                )
                }
                {(this.state.pageVideo || this.state.pageError) && (
                    <>
                        <button className="button-9" role="button" key={this.getButtonText()} onClick={() => {
                            if (this.state.pageStart) {
                                this.setPageScan();
                            } else {
                                this.setPageStart();
                            }
                        }}>
                            {this.getButtonText()}
                        </button>
                    </>
                )
                }

                <div>
                    {this.getFooter()}
                </div>
            </div>
        );
    }
}


ReactDOM.render(
    <Main/>,
    document.getElementById('react-mountpoint')
);
