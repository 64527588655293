import React, { Component } from "react";

class ResponseMsg extends Component {
    render() {
        if (!this.props.msg) {
            return <div>No Response yet</div>
        }

        let classMsg = "msg";
        if(this.props.error){
            classMsg = 'errorMsg';
        }

        return (
            <div id={classMsg}  >
                <p>{this.props.msg }</p>
            </div>
        );
    }
}

export default ResponseMsg;