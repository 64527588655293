import ConfigConst from "../config.json";


export const alertFct = () => {
    alert("timeout");
}


export const extractPidFromQrCode  = (qrCode) =>{
    if(qrCode.match(".*B.*E.*")) {
        qrCode = qrCode.substring(0, qrCode.indexOf("B"));
    }
    return qrCode.toString();
}


export const  logError = (err) =>  {
    console.error(err);
};

