import React, {Component} from "react";
import ConfigConst from "../config.json";

class ComplexResponseMsg extends Component {
    render() {

        let bundle = ConfigConst.MSG.STD.SUCCESS.GER;
        if (this.props.langId == ConfigConst.LANG.ENG.ID) {
            bundle = ConfigConst.MSG.STD.SUCCESS.ENG;
        }

        if (!this.props.rsp) {
            return <div>No Response yet</div>
        }


        return (
            <div id="msg">
                <p><b>{bundle.L1} {this.props.rsp.userName}</b>,</p>
                <p>{bundle.L2}<u>{this.props.rsp.courseName}</u>.</p>
                <p>{bundle.L3} <i>{this.props.rsp.startDate}</i><br/>
                    {bundle.L4} <i>{this.props.rsp.endDate}</i><br/>
                    {bundle.L5} <i>{this.props.rsp.location}</i><br/>
                    {bundle.L6} <i>{this.props.rsp.room}</i></p>
                <p>{this.props.rsp.msg}</p>
            </div>
        );
    }
}

export default ComplexResponseMsg;